// import { useState, useEffect } from 'react';
import './App.css';

import { IconContext } from 'react-icons/lib';
import { AiFillLinkedin, AiOutlineMail } from 'react-icons/ai';

// const checkAPI = () => {
// 	return fetch('/api/up').then(response => response.status)
// }

export default function App() {
	// const [status, setStatus] = useState(false);

	// useEffect(() => {
	// 	setStatus(checkAPI())
	// }, [])

	return (
		<>
			<div className="App-content">
				<h1 className="main-heading">Coming Soon!</h1>
				<div className="social-icon-container">
					<IconContext.Provider value={{ className: 'social-icons' }}>
						<a href='https://www.linkedin.com/in/efe-harmankaya'>
								<AiFillLinkedin />
						</a>
						<a href='mailto:efe@harmankaya.dev'>
							<AiOutlineMail />
						</a>
					</IconContext.Provider>
				</div>
				{/* <p className="connection-status">Connection Status: {status ? '✅' : '❌'}</p> */}
			</div>
		</>
	);
}

